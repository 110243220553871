const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://de1m2hqp3g.execute-api.ap-southeast-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://ljsj0q95kj.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://5lktp971hk.execute-api.ap-southeast-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://okh6i0qqgi.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
};

export default config;